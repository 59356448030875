<template>
  <v-container>
    <div class="pa-2">
      <h1 class="primary--text">Paramètres</h1>
      <router-link to="/admin/referentiel"><h3 class="primary--text">Référentiel de prix</h3></router-link>
      <router-link to="/admin/modeldocs"><h3 class="primary--text">Modèles de document</h3></router-link>
      <router-link to="/admin/statuts"><h3 class="primary--text">Statuts de dossier</h3></router-link>
      <router-link to="/admin/users"><h3 class="primary--text">Utilisateurs</h3></router-link>
      <router-link to="/admin/select"><h3 class="primary--text">Personnaliser les champs à choix multiples</h3></router-link>
      <router-link to="/admin/objectifs"><h3 class="primary--text">Définir les objectifs</h3></router-link>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Admin",

}
</script>
